import React, { Component } from "react"
import gsap, { CSSPlugin } from "gsap"
import { MotionPathPlugin } from "gsap/MotionPathPlugin"

gsap.registerPlugin(MotionPathPlugin, CSSPlugin)

class Skier extends Component {
  constructor(props) {
    super(props)
    this.skier = null
    this.path = null
  }

  componentDidMount() {
    gsap.to(this.skier, {
      duration: 6,
      repeat: -1,
      ease: "none",
      // yoyo: true,
      motionPath: {
        path: this.path,
        align: this.path,
        ease: "none",
        alignOrigin: [0.5, 0.5],
        autoRotate: true,
      },
    })
  }

  render() {
    return (
      <svg
        id="Layer_1"
        data-name="Layer 1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 745.5 179.6"
      >
        <path
          fill="none"
          ref={div => (this.path = div)}
          d="M56.3,103.7c8.8-2.7,17.4-6.6,26.5-7.1c15.2-0.8,29.4,7.7,44.5,8.7c19,1.3,36.8-9.1,55.7-11.7
	c25.4-3.5,50.3,7.3,74.1,16.8c23.8,9.5,50.2,18,74.6,10.1c9.2-3,17.5-8.2,26.6-11.4c9.3-3.3,19.2-4.4,29-5c15.7-1,31.5-0.8,47.1,0.6
	c11.9,1.1,24.8,2.6,35.1-3.5c12.3-7.2,16.7-24.4,11.3-37.6s-18.9-22-33.1-24c-7.2-1-14.9-0.3-20.9,3.7c-10.2,6.6-13,21.4-8.2,32.6
	s15.5,18.9,27,22.9c11.5,3.9,23.8,4.6,36,5.2c9.1,0.5,18.2,0.9,27.3,1.4c2.5,0.1,5.1,0.3,7.6,0.7c0,0,0-0.1,0.1-0.1l102.6,18.5"
        />
        <g ref={div => (this.skier = div)}>
          <path
            d="M219.8,140.6c5.1,2.9,16,4.8,27.4,5l0,12.5l71.5-18.4l0,0c0,0,0,0,0,0c0,0,0.1,0,0.1,0c0,0,0,0,0.1,0c0.1,0,0.1,0,0.2-0.1
	c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.2-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0.1,0,0.1-0.1c0.1-0.1,0.1-0.2,0.2-0.3c0,0,0,0,0,0c0,0,0,0,0,0
	c0,0,0,0,0,0c0-0.1,0.1-0.2,0.1-0.3c0,0,0,0,0-0.1c0,0,0-0.1,0-0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0-0.1,0-0.1,0-0.2
	c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1
	c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0,0,0-0.1-0.1-0.1c0,0,0-0.1-0.1-0.1c0,0,0,0,0,0c0,0,0,0-0.1-0.1c0,0,0,0,0,0c0,0-0.1-0.1-0.1-0.1
	c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1,0c-0.1,0-0.1-0.1-0.2-0.1c0,0,0,0,0,0L283,116.7c0,0-0.1,0-0.1,0c-7.2-3.4-12.3-4.3-21.2-3.7
	c-11.2,0.7-19,3.7-20.3,7.7c-0.3,1.1-0.5,3.2,2.1,5.3l1.3,1l-7.8,0.7c-4.3,0.4-18.6,2.2-20,7.7C216.6,136.8,216.9,138.7,219.8,140.6
	z M250.2,145.6c2.8,0,5.7-0.2,8.4-0.4l41.4-3.7l-49.8,12.8L250.2,145.6z M244.2,121.6c0.7-2.3,7.2-5,17.7-5.7
	c8.4-0.5,13,0.3,19.7,3.4l29,16.2l-61.2-8.8l-4.1-3.1C244.4,122.9,244,122.2,244.2,121.6z M219.9,136.2c0.4-1.5,5.4-4.4,17.3-5.5
	l11.3-1l57,8.2l-47.1,4.3c-13.9,1.3-30.8-0.6-37-4.2C220.3,137.3,219.8,136.7,219.9,136.2z"
          />
        </g>
      </svg>
    )
  }
}

export default Skier
